import React, { useState, useEffect } from 'react';
import CleanPlace from '../assets/Images/CleanPlace.jpg';
import Logo from '../assets/Images/logo.png';
import CleanHome from '../assets/Images/CleanHome.jpg';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import FooterPage from '../components/FooterPage';
// import BackToTopButtom from '../components/BackToTopButtom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ClipLoader } from "react-spinners";
import { storage } from "../firebase";
import { listAll, getDownloadURL, ref } from "firebase/storage";
import LazyLoad from 'react-lazyload';


const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [carouselOneImages, setCarouselOneImages] = useState([]);
  const [carouselTwoImages, setCarouselTwoImages] = useState([]);

  useEffect(() => {
    setLoading(true);
    const storageRef = ref(storage, 'Images/');
    
    // List all files in the folder
    listAll(storageRef)
      .then((res) => {
        // Fetch URLs for all images
        return Promise.all(res.items.map((item) => getDownloadURL(item)));
      })
      .then((urls) => {
        // Assuming you want the first half of images for carousel one
        // and the second half for carousel two
        const midIndex = Math.ceil(urls.length / 2);
        setCarouselOneImages(urls.slice(0, midIndex));
        setCarouselTwoImages(urls.slice(midIndex));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);
  return (
    <div className="mx-auto">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <ClipLoader color={"#f84444"} loading={loading} size={60} />
        </div>
      ) : (
        <>
          <div className={`relative w-full h-96 `}>
            <img src={CleanPlace} className='absolute w-full h-96 object-cover' alt='Clean place' />
            <div className="w-full h-96 bg-black opacity-50"></div>
            <div className="absolute top-1/3 left-2/3 transform -translate-x-1/2 text-4xl font-bold my-10 text-[#ffffff]">
              <h1 className="text-5xl font-bold text-center my-6">Gallery</h1>
              <h6 className='text-xl text-center text-[#d4af37] ml-5'>Experience the art of immaculate living.</h6>
            </div>
          </div>
                  <p className="text-center text-4xl font-bold px-1 py-6 my-4 first-letter:text-5xl text-[#d4af37]">Our Work!</p>
          <div className="flex flex-col sm:flex-row justify-center bg-gradient-color mb-5">
            <div className="flex flex-col sm:flex-row w-full max-w-6xl">
              <div className="flex justify-center items-center w-full sm:w-1/2 p-4">
                <div>
                  <Carousel autoPlay infiniteLoop useKeyboardArrows showStatus={false} showThumbs={false} showIndicators={false} transitionTime={1000} swipeable stopOnHover interval={6000}>
                    {carouselOneImages.map((url, index) => (
                      <LazyLoad key={index} height={550} offset={100} placeholder={<p>Loading...</p>}>
                        <div className="relative">
                          <img src={url} className='rounded-md' style={{ width: '800px', height: '550px' }} alt='From Firebase' />
                          <p className="absolute left-1/3 bottom-0 bg-opacity-30 bg-black text-white font-light p-2">Fabiana Fabulous Cleaning Service LLC</p>
                        </div>
                      </LazyLoad>
                    ))}
                  </Carousel>
                </div>
              </div>
              <div className="flex justify-center items-center w-full sm:w-1/2 p-4">
                <div>
                  <Carousel autoPlay infiniteLoop useKeyboardArrows showStatus={false} showThumbs={false} showIndicators={false} transitionTime={1000} swipeable stopOnHover interval={6000}>
                    {carouselTwoImages.map((url, index) => (
                      <LazyLoad key={index} height={550} offset={100} placeholder={<p>Loading...</p>}>
                        <div className="relative">
                          <img src={url} className='rounded-md' style={{ width: '800px', height: '550px' }} alt='From Firebase' />
                          <p className="absolute left-1/3 bottom-0 bg-opacity-30 bg-black text-white font-light p-2">Fabiana Fabulous Cleaning Service LLC</p>
                        </div>
                      </LazyLoad>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <h1 className="text-4xl bg-gradient-colors text-[#ffffff] font-serif font-bold text-center px-16 py-12 bloom-text">Refer us and get 15% off on your next cleaning!</h1>
          <div className="relative">
            <img src={CleanHome} className="w-full h-64 md:h-96 object-cover" alt="Clean home" />
            <div className="absolute inset-0 bg-black opacity-75"></div>
            <img src={Logo} className="absolute top-8 left-24 transform -translate-x-1/2 md:top-1/2 md:left-1/4 md:-translate-y-1/2 w-40 h-40 md:w-64 md:h-64" alt="Fabiana Fabulous Cleaning Services LLC" />
            <h5 className="absolute top-12 left-2/3 transform -translate-x-1/3 text-[#d4af37] text-xl md:text-xl md:top-1/2 md:left-2/3 md:-translate-y-1/2 text-center">
              WE MAKE YOUR HOME LOOK LIKE NEW AGAIN
            </h5>
            <div className="absolute top-48 left-1/2 transform -translate-x-1/2 md:top-2/3 md:left-2/3 md:-translate-y-1/2 flex items-center bg-[#43b0f1] hover:bg-gradient-colors px-4 py-2 rounded-md">
              <button className="text-white flex items-center text-base md:text-lg hover:text-[#d4af37]">
                <UilCalendarAlt className="mr-2 button-animate " size="18" />
                <a href="tel:425-829-6092">Schedule Today</a>
              </button>
            </div>
          </div>
          <FooterPage />
          {/* <div className='p-2'>
            <BackToTopButtom />
          </div> */}
        </>
      )}
    </div>
  );
};

export default Gallery;