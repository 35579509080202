
import React, { useState } from 'react';
import { motion } from 'framer-motion';


function CleaningForm() {
    const [formData, setFormData] = useState({ sqft: '', bathrooms: '', frequency: '', bedrooms: '', firstTime: '' });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFetchingPrice, setIsFetchingPrice] = useState(false);
    const [price, setPrice] = useState(null);
    const [isFirstTime, setIsFirstTime] = useState(true);

    const handleFirstTimeSubmit = (e) => {
        e.preventDefault();

        // If the user selects "no" for the first time cleaning, set isFirstTime to false
        if (formData.firstTime === 'no') {
            setIsFirstTime(false);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'firstTime' && e.target.value === 'yes') {
            window.location.href = "tel:4258296092";
        }
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.sqft = formData.sqft ? "" : "This field is required.";
        tempErrors.bedrooms = formData.bedrooms ? "" : "This field is required.";
        tempErrors.bathrooms = formData.bathrooms ? "" : "This field is required.";
        tempErrors.frequency = formData.frequency ? "" : "This field is required.";
        tempErrors.firstTime = formData.firstTime ? "" : "This field is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setIsFetchingPrice(true);
            fetch('https://us-central1-fabicleaners-28c5e.cloudfunctions.net/calculatePrice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(response => response.text())
                .then(text => {
                    const data = JSON.parse(text);
                    setPrice(data.price);
                    setIsFetchingPrice(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setIsFetchingPrice(false);
                });
            setIsSubmitted(true);
        }
    };

    const Spinner = () => (
        <motion.div
            style={{ border: "4px solid #5A67D8", borderTop: "4px solid #E2E8F0", borderRadius: "50%", width: "2rem", height: "2rem" }}
            animate={{ rotate: 360 }}
            transition={{ ease: "linear", duration: 3, loop: Infinity }}
        />
    );

    return (
        <div className="flex items-center justify-center min-h-screen p-4 md:p-6 bg-gray-50">
            <div className="w-full max-w-md md:max-w-lg">
                {isSubmitted ? (
                    <>
                        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                            <p className="text-lg md:text-xl font-bold form-animate">Your estimated price!</p>
                            <div className="mt-4 text-start">
                                <p className="text-md md:text-lg">Square Footage: {formData.sqft}</p>
                                <p className="text-md md:text-lg">Number of Bedrooms: {formData.bedrooms}</p>
                                <p className="text-md md:text-lg">Number of Bathrooms: {formData.bathrooms}</p>
                                <p className="text-md md:text-lg">Cleaning Frequency: {formData.frequency}</p>
                                {isFetchingPrice ? (
                                    <Spinner />
                                ) : (
                                    price && <p className="font-bold text-lg md:text-xl">Estimated Price: ${Math.floor(price)}</p>
                                )}
                            </div>
                            <a href="tel:425-829-6092" className="text-lg md:text-xl font-bold" title='Call US'>Call Us to give you real Price!<br></br>(425)829-6092</a>
                        </div>
                    </>
                ) : (
                    isFirstTime ? (
                        <form onSubmit={handleFirstTimeSubmit} className="space-y-2 px-4 py-3 md:px-7 md:py-3 m-2">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    <p className="text-lg font-bold">Cleaning Rate?</p>
                                </label>
                                <select name="firstTime" value={formData.firstTime} onChange={handleChange} required
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <option value="">--Choose an option--</option>
                                    <option value="yes">First Time Cleaning Hourly Rate $180</option>
                                    <option value="no">Quote Your Place!</option>
                                </select>
                            </div>
                            <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Submit
                            </button>
                        </form>
                    ) : (
                        <div className='bg-gradient-color w-full max-w-lg mx-auto rounded-md p-4 md:p-6'>
                            <h2 className="text-xl md:text-2xl font-extrabold text-gray-900">Quick Estimate Form</h2>
                            <p className="my-1 text-sm md:text-base text-gray-600 font-semibold p-1 text-center">
                                Please fill out the form below to<br /> receive a quick estimate.
                            </p>
                            <form onSubmit={handleSubmit} className="space-y-2 px-4 py-3 md:px-7 md:py-3 m-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Square Footage:
                                    </label>
                                    <input type="number" name="sqft" value={formData.sqft} onChange={handleChange} required
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    {errors.sqft && <div className="text-red-500 text-xs mt-1">{errors.sqft}</div>}
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Number of Bedrooms:
                                    </label>
                                    <input type="number" name="bedrooms" value={formData.bedrooms} onChange={handleChange} required
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    {errors.bedrooms && <div className="text-red-500 text-xs mt-1">{errors.bedrooms}</div>}
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Number of Bathrooms:
                                    </label>
                                    <input type="number" name="bathrooms" value={formData.bathrooms} onChange={handleChange} required
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    {errors.bathrooms && <div className="text-red-500 text-xs mt-1">{errors.bathrooms}</div>}
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Cleaning Frequency:
                                    </label>
                                    <select name="frequency" value={formData.frequency} onChange={handleChange} required
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option value="">--Please choose an option--</option>
                                        <option value="weekly">Every Week</option>
                                        <option value="biweekly">Biweekly</option>
                                    </select>
                                    {errors.frequency && <div className="text-red-500 text-xs mt-1">{errors.frequency}</div>}
                                </div>
                                <div className="text-center">
                                    <p className="text-xs text-gray-600 font-light">
                                        **Please note that prices may change depending on the home's <br />condition.**
                                    </p>
                                </div>
                                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Submit
                                </button>
                            </form>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}
export default CleaningForm;




    