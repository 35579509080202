import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CleanHome from '../assets/Images/CleanHome.jpg';
import ReCAPTCHA from "react-google-recaptcha";

const Request = () => {
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        projectDescription: ''
    });

    const [errors, setErrors] = useState({});
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = "Name is required";
        if (!formData.phone) formErrors.phone = "Phone is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (!formData.projectDescription) formErrors.projectDescription = "Job description is required";

        setErrors(formErrors);

        // If the errors object is empty, the form is valid
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Ensure form validation
        if (!validateForm()) {
            return;
        }

        // Ensure reCAPTCHA token is available
        if (!recaptchaValue) {
            alert('Please complete the reCAPTCHA');
            return;
        }

        // Add the reCAPTCHA token to the form data
        const formDataWithRecaptcha = {
            ...formData, // Spread the existing form data
            recaptcha: recaptchaValue // Add the reCAPTCHA token
        };

        try {
            // Make the POST request to your Google Cloud Function
            const response = await axios.post('https://us-central1-fabicleaners-28c5e.cloudfunctions.net/sendsms', formDataWithRecaptcha);

            // Check for response status or any other success criteria
            if (response.status === 200) {
                setIsSubmitted(true);
                setFormData({ name: '', phone: '', email: '', projectDescription: '' });

                // Reset the form submission flag after 5 seconds
                setTimeout(() => {
                    setIsSubmitted(false);
                }, 5000);
            } else {
                console.error('Unexpected response:', response);
                alert('Failed to send message');
            }
        } catch (error) {
            console.error('Error sending SMS:', error);
            alert(`Failed to send message: ${error.response?.data?.message || error.message}`);
        } finally {
            // Perform any necessary cleanup here (optional)
        }
    };

    const handleRecaptcha = value => {
        setRecaptchaValue(value);
    };

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    return (
        <>
            <div className={`relative w-full h-96  `}>
                <img src={CleanHome} className='absolute w-full h-96' alt='clean place ' />
                <div className=" w-full h-96 bg-black opacity-50"></div>
                <div className="absolute top-1/3 left-2/4 text-4xl font-bold my-10 text-[#ffffff] ">
                    <div className='flex-flex justify-center'>
                        <h1 className="text-5xl font-bold text-center my-8 flex justify-center">Book Now!</h1>
                        <p className='text-xl text-center text-[#d4af37]'>Experience the art of immaculate living.</p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 p-4 md:p-10">
                <div className="flex flex-col sm:ml-8 md:ml-16 lg:ml-24">
                    <h2 className="text-center text-2xl sm:text-3xl md:text-4xl p-3">
                        Fabiana Fabulous Cleaning Services LLC
                    </h2>
                    <p className="text-center text-base sm:text-lg p-3">
                        Proudly serving King & Snohomish county since 2011.
                    </p>

                    <h5 className="flex items-center p-4 text-sm sm:text-base lg:text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2baaff] mr-3 sm:mr-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 01.083 1.32l-.083.094L8 15l-4-4a1 1 0 011.497-1.32l.083.094L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        Rated Highly on Google Reviews
                    </h5>

                    <h5 className="flex items-center p-4 text-sm sm:text-base lg:text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2baaff] mr-3 sm:mr-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 01.083 1.32l-.083.094L8 15l-4-4a1 1 0 011.497-1.32l.083.094L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        Locally owned and family operated
                    </h5>

                    <h5 className="flex items-center p-4 text-sm sm:text-base lg:text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2baaff] mr-3 sm:mr-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 01.083 1.32l-.083.094L8 15l-4-4a1 1 0 011.497-1.32l.083.094L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        Custom quotations provided at no cost to you
                    </h5>

                    <h5 className="flex items-center p-4 text-sm sm:text-base lg:text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2baaff] mr-3 sm:mr-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 01.083 1.32l-.083.094L8 15l-4-4a1 1 0 011.497-1.32l.083.094L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        We are licensed, bonded, and insured
                    </h5>

                    <h5 className="flex items-center p-4 text-sm sm:text-base lg:text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2baaff] mr-3 sm:mr-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 01.083 1.32l-.083.094L8 15l-4-4a1 1 0 011.497-1.32l.083.094L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        100% Satisfaction guaranteed! Or money back
                    </h5>
                </div>
                <div className="md:flex flex-col items-center m-5">
                    <div className="text-center text-4xl font-bold mb-4">
                        Request A <span className="text-[#ffa600] whitespace-pre"> Free </span> Estimate
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <h6 className="text-center text-lg font-semibold">
                            WE DON'T SHARE YOUR PERSONAL INFORMATION WITH ANYONE.
                        </h6>
                        <form onSubmit={handleSubmit} className="w-full max-w-2xl rounded-md shadow-xl p-3">
                            <p className="text-center text-sm font-semibold m-2 p-2">
                                Please fill out the form below and we will get back to you as soon as possible.
                            </p>
                            <div className="mb-4 shadow-md">
                                <input
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="shadow appearance-none border border-[#92d9f7] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    required
                                />
                                {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                            </div>
                            <div className="mb-4 shadow-md">
                                <input
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="shadow appearance-none border border-[#92d9f7] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="phone"
                                    type="text"
                                    placeholder="Phone"
                                    required
                                />
                                {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
                            </div>
                            <div className="mb-4 shadow-md">
                                <input
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="shadow appearance-none border border-[#92d9f7] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    required
                                />
                                {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                            </div>
                            <div className="mb-6 shadow-md">
                                <textarea
                                    value={formData.projectDescription}
                                    onChange={handleChange}
                                    className="shadow appearance-none border border-[#92d9f7] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="projectDescription"
                                    placeholder="Job description"
                                    required
                                />
                            </div>
                            <div>
                                <h4 className="text-start font-bold p-1">Consent</h4>
                                <p className="text-xs border border-[#92d9f7] p-2">
                                    By checking the box and clicking REQUEST ESTIMATE below, you’re giving us permission to communicate with you via a call, text or email. We promise to only communicate when necessary for things regarding your quote request, reminders or an occasional special offer. No purchase is required. Plus, if you ever change your mind and you no longer want us to contact you, reply END or STOP to any email or text message to immediately opt out. Standard text rates apply.
                                </p>
                            </div>
                            <div className="mb-4">
                                <label className="flex items-center hover:text-[#2baaff] text-sm">
                                    <input
                                        type="checkbox"
                                        checked={isAgreed}
                                        onChange={handleCheckboxChange}
                                        className="mr-2 "
                                    />
                                    I agree to <Link to="/privacy" 
                                        className="hover:underline ml-2 text-red-600"
                                        smooth={true}
                                        duration={500}
                                        >Privacy-Policy</Link>.
                                </label>
                            </div>
                            <ReCAPTCHA sitekey="6Ldl7WMpAAAAADw6ax33rtFGknxmChmM3_JzaQYj" onChange={handleRecaptcha} className="my-8 flex justify-center" />
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-blue-500 hover:bg-gradient-colors text-white w-full font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                    disabled={!Object.values(formData).some(x => x !== null && x !== '')}
                                >
                                    Request Estimate
                                </button>
                            </div>
                            <div className="text-[#2baaff] text-center my-3">
                                {isSubmitted && <p>Message sent successfully!</p>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
        //? for the reCAPTCHA sitekey, you can use the test key 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
    )
}

export default Request