import React, {useState} from "react";
import Privacy from "../assets/Images/Privacy.webp";
import FooterPage from "./FooterPage";

const Section = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSection = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mb-4">
            <div className="flex justify-between items-center cursor-pointer bg-gray-200 p-4 rounded" onClick={toggleSection}>
                <h2 className="text-xl font-semibold text-[#003366] hover:text-[#d4af37]">{title}</h2>
                <span className="text-xl font-semibold">{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && <h5 className="p-4 bg-gray-100 rounded">{children}</h5>}
        </div>
    );
};

const PrivacyPolicy = () => {
    return (

        <div className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg">
            <div className={`relative w-full h-96  `}>
                <img src={Privacy} className='absolute w-full h-96' alt='clean place ' />
                <div className=" w-full h-96 bg-black opacity-50"></div>
                <div className="absolute top-1/3 left-2/4 text-4xl font-bold my-10 text-[#ffffff] ">
                    <div className='flex-flex justify-center'>
                        <h1 className="text-5xl font-bold text-center my-8 flex justify-center">Privacy Policy</h1>
                    </div>
                </div>
            </div>
            <div className="space-y-6">
                <section>
                    <h2 className="text-2xl font-semibold m-3">1. Introduction</h2>
                    <h3 className="text-gray-700">
                        Fabiana Fabulous Cleaning Services LLC ("we," "us," "our") is committed to protecting
                        your privacy. This Privacy Policy explains how we collect, use, and
                        share information when you use our website and services.
                    </h3>
                </section>

                <Section title="2. Information We Collect">
                    {/* <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2> */}
                    <p className="text-gray-700">
                        We may collect and process the following types of personal
                        information:
                    </p>
                    <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                        <li>
                            <strong>Contact Information:</strong> Your name, email address,
                            phone number, and any other information you provide when
                            submitting a request or contacting us.
                        </li>
                        <li>
                            <strong>Usage Information:</strong> Data on how you interact with
                            our website, such as IP addresses, device information, and
                            browsing patterns.
                        </li>
                    </ul>
                </Section>

                <Section title="3. How We Use Your Information">
                    {/* <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2> */}
                    <p className="text-gray-700">
                        We use your information for purposes including:
                    </p>
                    <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                        <li>
                            <strong>Responding to Your Requests:</strong> To provide the
                            services or information you request.
                        </li>
                        <li>
                            <strong>Marketing Communications:</strong> With your consent, we
                            may send you promotional offers, updates, and other relevant
                            information.
                        </li>
                        <li>
                            <strong>Improving Our Services:</strong> To analyze and improve
                            our website and services.
                        </li>
                    </ul>
                </Section>

                <Section title="4. How We Share Your Information">
                    {/* <h2 className="text-2xl font-semibold mb-4">4. How We Share Your Information</h2> */}
                    <p className="text-gray-700">
                        We do not sell or share your personal information.
                    </p>
                    <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                        <li>
                            <strong>Service Providers:</strong> We may share your data with
                            trusted partners who assist us in providing our services.
                        </li>
                        <li>
                            <strong>Legal Requirements:</strong> We may disclose your
                            information to comply with legal obligations.
                        </li>
                    </ul>
                </Section>

                <Section title="5. Your Choices">
                    {/* <h2 className="text-2xl font-semibold mb-4">5. Your Choices</h2> */}
                    <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                        <li>
                            <strong>Opt-Out:</strong> You may opt out of marketing
                            communications at any time by clicking the "unsubscribe" link in
                            our emails or by contacting us directly.
                        </li>
                        <li>
                            <strong>Access and Correction:</strong> You may request access to
                            your personal information and ask us to update, correct, or delete
                            it.
                        </li>
                    </ul>
                </Section>

                <Section title="6. Security">
                    {/* <h2 className="text-2xl font-semibold mb-4">6. Security</h2> */}
                    <p className="text-gray-700">
                        We take reasonable measures to protect your information. However, no
                        online transmission is entirely secure, and we cannot guarantee
                        absolute security.
                    </p>
                </Section>

                <Section title="7. Changes to This Policy">
                    {/* <h2 className="text-2xl font-semibold mb-4">7. Changes to This Policy</h2> */}
                    <p className="text-gray-700">
                        We may update this policy occasionally. Changes will be posted on
                        this page with an updated effective date.
                    </p>
                </Section>

                <Section title="8. Contact Us">
                    {/* <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2> */}
                    <p className="text-gray-700">
                        If you have questions about this policy, please contact us at:
                    </p>
                    <ul className="ml-6 mt-2 text-gray-700">
                        <li>Fabiana Fabulous Cleaning Services LLC</li>
                        <li>fsandes73@gmail.com</li>
                        
                    </ul>
                </Section>
            </div>
            <FooterPage />
        </div>
    );
};

export default PrivacyPolicy;
