// import React, { useState, useEffect } from 'react';
// import Logo from '../assets/Images/logo.png';
// import axios from 'axios';
// import { motion } from 'framer-motion';
// import { UilCommentAltDots, UilTimesCircle } from '@iconscout/react-unicons';

// const ChatNow = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [isSubmitted, setIsSubmitted] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [formData, setFormData] = useState({
//         name: '',
//         phone: '',
//         email: '',
//         projectDescription: ''
//     });

//     // Handle form field changes
//     const handleChange = (event) => {
//         setFormData({ ...formData, [event.target.name]: event.target.value });
//     };

//     // Reset success message after a few seconds
//     useEffect(() => {
//         if (isSubmitted) {
//             const timer = setTimeout(() => setIsSubmitted(false), 3000);
//             return () => clearTimeout(timer);
//         }
//     }, [isSubmitted]);

//     // Handle form submission
//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         // Form validation
//         if (!formData.name || !formData.phone || !formData.email || !formData.projectDescription) {
//             alert('Please fill out all fields');
//             return;
//         }
//         setIsLoading(true);
//         try {
//             await axios.post('http://localhost:3005/send-sms', formData);
//             setIsSubmitted(true);
//             setFormData({ name: '', phone: '', email: '', projectDescription: '' });
//         } catch (error) {
//             console.error(error);
//             alert('Failed to send message');
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return (
//         <div className="chatnow__container">
//             <motion.button
//                 whileHover={{ scale: 1.3 }}
//                 initial={{ scale: 1 }}
//                 animate={{ rotate: 360, scale: 1 }}
//                 transition={{ duration: 2, loop: Infinity }}
//                 title='Chat Now'
//                 className="fixed right-4 bottom-4 bg-gradient-color text-white p-4 rounded-full cursor-pointer"
//                 onClick={() => setIsOpen(!isOpen)}
//             >
//                 {isOpen ? (
//                     <UilTimesCircle className="text-black w-7 h-7 rounded-full" />
//                 ) : (
//                     <UilCommentAltDots className="text-[#04030b] rounded-full w-9 h-9 chat-animate" />
//                 )}
//             </motion.button>
//             {isOpen && (
//                 <motion.div
//                     initial={{ opacity: 0, y: -50 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5 }}
//                     className="chatnow__form bg-gradient-color border border-gray-300 rounded p-4 fixed right-4 bottom-20 w-80 shadow-lg"
//                 >
//                     <div className="chatnow__logo flex items-center gap-1 mb-4">
//                         <img src={Logo} alt="Fabiana Fabulous Cleaning Services LLC" className="w-12 h-12 border rounded-full" />
//                         <p className="text-sm">Have a question? Enter the information below, and we will send you an SMS shortly.</p>
//                     </div>
//                     <form onSubmit={handleSubmit} className="space-y-3">
//                         <input
//                             type="text"
//                             name="name"
//                             value={formData.name}
//                             onChange={handleChange}
//                             placeholder="Name"
//                             required
//                             aria-label="Name"
//                             className="w-full p-2 border rounded"
//                         />
//                         <input
//                             type="tel"
//                             name="phone"
//                             value={formData.phone}
//                             onChange={handleChange}
//                             placeholder="Phone"
//                             required
//                             aria-label="Phone"
//                             className="w-full p-2 border rounded"
//                         />
//                         <input
//                             type="email"
//                             name="email"
//                             value={formData.email}
//                             onChange={handleChange}
//                             placeholder="Email"
//                             required
//                             aria-label="Email"
//                             className="w-full p-2 border rounded"
//                         />
//                         <textarea
//                             name="projectDescription"
//                             value={formData.projectDescription}
//                             onChange={handleChange}
//                             placeholder="Job Description"
//                             required
//                             aria-label="Job Description"
//                             className="w-full p-2 border rounded h-24 resize-none"
//                         />
//                         <button
//                             type="submit"
//                             disabled={isLoading}
//                             className={`w-full p-2 text-white rounded ${isLoading ? 'bg-gray-500' : 'bg-blue-600 hover:bg-blue-700'}`}
//                         >
//                             {isLoading ? 'Sending...' : 'Submit'}
//                         </button>
//                     </form>
//                     {isSubmitted && (
//                         <p className="text-center text-[#2baaff] mt-2">Message sent successfully!</p>
//                     )}
//                 </motion.div>
//             )}
//         </div>
//     );
// };

// export default ChatNow;

import React, { useState, useEffect } from 'react';
import Logo from '../assets/Images/logo.png';
import axios from 'axios';
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
import { UilCommentAltDots, UilTimesCircle } from '@iconscout/react-unicons';
import '../CSS/ChatNow.css';

const ChatNow = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(true);
    const [isAgreed, setIsAgreed] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        projectDescription: ''
    });

    // Helper function to check active hours (7 AM to 6 PM)
    const isWithinActiveHours = () => {
        const now = new Date();
        const currentHour = now.getHours();
        return currentHour >= 7 && currentHour < 18;
    };

    useEffect(() => {
        setShowNotification(isWithinActiveHours());
        const interval = setInterval(() => {
            setShowNotification(isWithinActiveHours());
        }, 3600000); // Check every hour
        return () => clearInterval(interval);
    }, []);

    // Automatically open the chat after 20 seconds if not engaged, only once per session
    useEffect(() => {
        if (!sessionStorage.getItem('chatAutoOpened')) {
            const timer = setTimeout(() => {
                if (!isOpen) {
                    setIsOpen(true);
                    setShowNotification(false);
                    sessionStorage.setItem('chatAutoOpened', 'true');
                }
            }, 12000); // 20 seconds

            // Clear timer if user opens chat manually
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    const toggleChat = () => {
        setIsOpen(!isOpen);
        setShowNotification(false);
        if (!isOpen) {
            sessionStorage.setItem('chatAutoOpened', 'true');
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData.name || !formData.phone || !formData.email || !formData.projectDescription) {
            alert('Please fill out all fields');
            return;
        }
        setIsLoading(true);
        try {
            await axios.post('https://us-central1-fabicleaners-28c5e.cloudfunctions.net/sendsms', formData);
            setIsSubmitted(true);
            setFormData({ name: '', phone: '', email: '', projectDescription: '' });
            setIsAgreed(false); // Reset the checkbox after successful submission
        } catch (error) {
            console.error(error);
            alert('Failed to send message');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="chatnow__container">
            <div className="relative">
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    className="chatnow__button"
                    onClick={toggleChat}
                >
                    {isOpen ? (
                        <UilTimesCircle className="text-white w-7 h-7" />
                    ) : (
                        <UilCommentAltDots className="text-white w-9 h-9" />
                    )}
                    {showNotification && <span className="notification-dot">1</span>}
                </motion.button>
            </div>

            <div className={`chatnow__form-container ${isOpen ? 'is-visible' : 'is-hidden'}`}>
                <div className="chatnow__logo">
                    <img src={Logo} alt="Fabiana Fabulous Cleaning Services LLC" />
                    <p className="text-sm">Have a question? Enter your info below, and we’ll SMS you soon.</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required className="chatnow__input" />
                    <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" required className="chatnow__input" />
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required className="chatnow__input" />
                    <textarea name="projectDescription" value={formData.projectDescription} onChange={handleChange} placeholder="Job Description" required className="chatnow__textarea" />
                    <div>
                        <h4 className="text-start font-bold p-1">Consent</h4>
                        <p className="text-xs border border-[#3b6bb4] p-2">
                            By checking the box and clicking SUBMIT below, you’re giving us permission to communicate with you via a call, text or email. We promise to only communicate when necessary for things regarding your quote request, reminders or an occasional special offer. No purchase is required. Plus, if you ever change your mind and you no longer want us to contact you, reply END or STOP to any email or text message to immediately opt out. Standard text rates apply.
                        </p>
                    </div>
                    <label className="flex items-center hover:text-[#2baaff] text-sm mb-3">
                        <input
                            type="checkbox"
                            checked={isAgreed}
                            onChange={handleCheckboxChange}
                            className="mr-2 "
                        />
                        I agree to <a href="/privacy" className="hover:underline ml-2 text-red-600">Privacy-Policy</a>.
                    </label>
                    <button type="submit" disabled={isLoading} className="chatnow__submit-btn">
                        {isLoading ? 'Sending...' : 'Submit'}
                    </button>
                </form>
                {isSubmitted && <p className="chatnow__success-msg">Message sent successfully!</p>}
            </div>
        </div>
    );
};

export default ChatNow;