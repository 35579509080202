import React, { useEffect } from 'react';
import NavBar from './components/NavBar';
import Home from './views/Home';
import About from './views/About';
import Services from './views/Services';
import Contact from './views/Contact';
import Gallery from './views/Gallery';
import Request from './components/Request';
import { useLoading } from './context/LoadingContext';
// import Loader from './components/Loader';
import TermsAndConditions from './views/TermandConditions';
import Privacy from './components/Privacy';
import ChatNow from './components/ChatNow';
import BackToTopButtom from './components/BackToTopButtom';
// import Popup from './components/Popup';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import '../src/CSS/Loader.css';
import logo from './assets/Images/logo.png';
import CategoryLoader from './components/CategoryLoader';
import './App.css';

function App() {
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };
    fetchData();
  }, [setIsLoading]);

  return (
    <Router>
      <div className="overflow-hidden">
        <NavBar />
        {/* <Popup /> */}
        {isLoading ? (
          <>
            <div className="loader">
              <img src={logo} alt="Loader Logo" className="spinning-logo" />
            </div>
            {/* <div className="text-cente">

            <CategoryLoader />
            </div> */}
          </>
        ) : (
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/request' element={<Request />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy' element={<Privacy />} />
          </Routes>
        )}
        <ChatNow />
        <div className='p-2 ml-8'>
          <BackToTopButtom />
        </div>
      </div>
    </Router>
  );
}

export default App;